<template>
  <div class="location-address row">
    <div v-if="!suppress.includes('name')" class="columns">
      <label
        ><span class="required">*</span> {{ $t('common.name') }}
        <input
          data-index="name"
          :class="{ invalid: !fields.name.valid }"
          @blur.stop="mask"
          type="text"
          v-model="location.name"
          :placeholder="$t('common.name')"
        />
      </label>
    </div>
    <!-- name -->
    <div v-if="!suppress.includes('type')" class="medium-6 columns">
      <label
        ><span class="required">*</span> {{ $t('forms.type') }}
        <input
          data-index="type"
          :class="{ invalid: !fields.type.valid }"
          @blur.stop="mask"
          type="text"
          v-model="location.type"
          :placeholder="$t('forms.type')"
        />
      </label>
    </div>
    <!-- type -->

    <div class="medium-6 columns">
      <label
        ><span class="required">*</span> {{ $t('forms.address') }}
        <input
          data-index="address.street"
          :class="{ invalid: !fields['address.street'].valid }"
          @blur="mask"
          type="text"
          v-model="location.address.street"
          :placeholder="$t('forms.address')"
        />
      </label>
    </div>
    <!-- address -->

    <div class="medium-6 columns">
      <label
        >{{ $t('forms.address2') }}
        <input
          data-index="address.apt"
          @blur.stop="mask"
          type="text"
          v-model="location.address.apt"
          :placeholder="$t('forms.address2')"
        />
      </label>
    </div>
    <!-- address2 -->

    <div class="medium-6 columns">
      <label
        ><span class="required">*</span> {{ $t('forms.city') }}
        <input
          data-index="address.city"
          :class="{ invalid: !fields['address.city'].valid }"
          @blur.stop="mask"
          type="text"
          v-model="location.address.city"
          :placeholder="$t('forms.city')"
        />
      </label>
    </div>
    <!-- city -->

    <div
      v-if="regions.length > 1 || other || !regions[0].hideCountryField"
      class="medium-6 columns"
    >
      <label
        ><span class="required">*</span> {{ $t('forms.country') }}
        <input
          v-if="!other && regions.length === 1"
          type="text"
          data-index="address.country"
          disabled
          v-model="countryConfig.name"
        />
        <select
          v-else-if="!location.userCountry"
          :class="{ invalid: !fields['address.country'].valid }"
          v-model="location.address.country"
          data-index="address.country"
          @blur="mask"
        >
          <option v-if="!defaultCountry" value="">Select...</option>
          <option
            v-for="option in regions"
            :key="option.countryCode"
            :value="option.countryCode"
            >{{ option.name }}</option
          >
          <option v-if="other" value="other">{{ $t('forms.other') }}</option>
        </select>
        <input
          v-else
          type="text"
          @blur="mask"
          data-index="address.country"
          v-model="location.address.country"
        />
        <input
          v-if="location.address.country === 'other'"
          :class="{ invalid: !userCountry }"
          type="text"
          @blur="mask"
          data-index="other-country"
          v-model="userCountry"
          :placeholder="$t('forms.idCountry')"
        />
      </label>
    </div>
    <!-- country -->

    <div class="medium-6 columns">
      <label
        ><span v-if="useProvince" class="required">*</span>
        {{
          countryConfig && countryConfig.provinceLabel
            ? $t("forms['" + countryConfig.provinceLabel + "']")
            : $t('forms.province')
        }}
        <select
          v-if="countryConfig && countryConfig.provinceList"
          v-model="location.address.prov"
          data-index="address.prov"
          :class="{ invalid: useProvince && !fields['address.prov'].valid }"
          @blur="mask"
        >
          <option value="" selected>{{ $t('client.login.select') }}...</option>
          <option
            v-for="option in countryConfig.provinceList"
            :key="option.provinceCode"
            :value="option.provinceCode"
          >
            {{ option.name }}
          </option>
        </select>
        <input
          v-else
          type="text"
          v-model="location.address.prov"
          @blur="mask"
          :class="{ invalid: useProvince && !fields['address.prov'].valid }"
          data-index="address.prov"
          :placeholder="$t('forms.province')"
        />
      </label>
    </div>
    <!-- province -->

    <div class="medium-6 columns">
      <label
        ><span v-if="usePostalcode" class="required">*</span>
        {{
          countryConfig && countryConfig.postalcodeName
            ? $t("forms['" + countryConfig.postalcodeName + "']")
            : $t('forms.postalCode')
        }}
        <input
          data-index="address.code"
          :class="{ invalid: usePostalcode && !fields['address.code'].valid }"
          :maxlength="countryConfig && countryConfig.postalcodeLength"
          @blur.stop="mask"
          type="text"
          v-model="location.address.code"
          :placeholder="countryConfig && countryConfig.postalcodePlaceholder"
        />
      </label>
    </div>
    <!-- postalCode -->

    <div v-if="!suppress.includes('email')" class="medium-6 columns">
      <label
        ><span class="required">*</span> {{ $t('forms.email') }}
        <input
          data-index="email"
          class="required"
          :class="{ invalid: !fields.email.valid }"
          v-model="location.email"
          type="email"
          required
          @blur="mask"
          :placeholder="$t('forms.email')"
        />
      </label>
    </div>
    <!-- email -->

    <div v-if="!suppress.includes('phone')" class="medium-6 columns">
      <label
        ><span class="required">*</span> {{ $t('forms.phone') }}
        <input
          data-index="phone"
          :class="{ invalid: !fields.phone.valid }"
          @blur.stop="mask"
          type="text"
          maxlength="20"
          v-model="location.phone"
          placeholder="000-000-0000"
        />
      </label>
    </div>
    <!-- phone -->

    <div v-if="canSubmit" class="small-12 columns actions">
      <p>&nbsp;</p>
      <!-- <p class="sml">*{{ $t("client.login.firstTime") }}</p> -->
      <!-- <p class="sml">*{{ $t("client.login.firstTime", {brand: appconfig.siteConfig.brand}) }}</p> -->
      <button type="button" class="button" @click.prevent="cancel">
        {{ $t('common.cancel') }}
      </button>
      <button type="button" class="button alert" @click.prevent="submit">
        {{ $t('buttons.submit') }}
      </button>
    </div>
  </div>
  <!-- actions -->
</template>

<script>
import { mapState, mapActions } from 'vuex'
// import _ from 'lodash/fp'

export default {
  props: {
    locationProp: {
      type: Object,
      default: () => ({})
    },
    canSubmit: {
      type: Boolean,
      default: true
    },
    suppress: {
      type: Array,
      default: () => ['type']
    },
    validateTrigger: {
      type: Boolean,
      default: false
    }
  },
  created() {
    if (this.locationProp._id) {
      this.newlocation = false
      this.location = Object.assign({}, this.locationProp)
      if (!this.location.userCountry)
        this.location.address.country = this.location.address.country.toUpperCase()
      for (const field in this.fields) {
        this.fields[field].blur = true
      }
    } else if (this.user.authenticated) {
      this.location.owner = this.user._id
      this.location.email = this.user.email
      this.fields.email.blur = true
      this.fields.email.valid = true
      this.location.type = this.user.role.content.en.name
    }

    if (/*!this.canSubmit && */ this.suppress.length) {
      this.suppress.forEach(field => {
        if (!this.fields[field]) return
        this.fields[field].valid = true
        this.fields[field].blur = true
        this.fields[field].required = false
      })
    }

    if (!this.location.address.country && this.defaultCountry) {
      this.location.address.country = this.defaultCountry
      this.fields['address.country'].blur = true
    }
  },
  data() {
    return {
      newlocation: true,
      location: {
        owner: '',
        name: '',
        type: '',
        contact: '',
        phone: '',
        email: '',
        address: {
          country: '',
          city: '',
          street: '',
          prov: '',
          apt: '',
          code: ''
        }
      },
      fields: {
        name: {
          blur: false,
          valid: true,
          required: true
        },
        type: {
          blur: false,
          valid: true,
          required: true
        },
        phone: {
          blur: false,
          valid: true,
          required: true
        },
        email: {
          blur: false,
          valid: true,
          required: true
        },
        'address.country': {
          blur: false,
          valid: true,
          required: true
        },
        'address.city': {
          blur: false,
          valid: true,
          required: true
        },
        'address.street': {
          blur: false,
          valid: true,
          required: true
        },
        'address.prov': {
          blur: false,
          valid: true,
          required: true
        },
        'address.code': {
          blur: false,
          valid: true,
          required: true
        },
        'address.apt': {
          blur: false,
          valid: true
        }
      },
      userCountry: '',
      restrictedChars: ['>', '<', '\\', '=']
    }
  },
  watch: {
    validateTrigger(val) {
      // console.info(' ::: watch validateTrigger', val)
      if (val) this.validate()
    }
  },
  computed: {
    ...mapState({
      user: ({ App }) => App.user,
      locations: ({ Location }) => Location.locations,
      regions: ({ App }) => App.constants.regionConfig.regions,
      other: ({ App }) => App.constants.regionConfig.userDefined
    }),
    defaultCountry() {
      if (this.regions.length === 1) return this.regions[0].countryCode
      const def = this.regions.find(r => r.default)
      return def ? def.countryCode : false
    },
    countryConfig() {
      let c =
        this.location.address.country !== 'other' &&
        this.location.address.country
      if (c) {
        return this.regions.find(r => r.countryCode === c)
      } else return false
    },
    useProvince() {
      let c =
        this.location.address.country !== 'other' &&
        this.location.address.country
      if (c) {
        c = this.regions.find(r => r.countryCode === c)
        return !!c && c.province
      } else return this.location.address.country !== 'other'
    },
    usePostalcode() {
      let c =
        this.location.address.country !== 'other' &&
        this.location.address.country
      if (c) {
        c = this.regions.find(r => r.countryCode === c)
        return !!c && c.postalcode
      } else return this.location.address.country !== 'other'
    },
    provinceList() {
      let c =
        this.location.address.country !== 'other' &&
        this.location.address.country
      if (c) {
        c = this.regions.find(r => r.countryCode === c)
        return !!c && c.provinceList
      } else return false
    },
    invalid() {
      const fieldsValid = Object.values(this.fields)
        .filter(f => f.required)
        .every(f => f.blur && f.valid)
      const countryValid =
        this.location.address.country &&
        (this.location.address.country !== 'other' || this.userCountry)
      return !(fieldsValid && countryValid)
    }
  },
  methods: {
    ...mapActions(['getLocations']),
    mask(evt) {
      // console.info(' ::: mask', evt)
      let fieldValid = false
      const val = this.restrictedChars.reduce((acc, i) => {
        acc = acc.replaceAll(i, '')
        return acc
      }, evt.target.value)
      evt.target.value = val
      if (this.fields[evt.target.dataset.index].required && !val) {
        this.fields[evt.target.dataset.index].blur = true
        this.fields[evt.target.dataset.index].valid = false
        fieldValid = false
        return
      }
      if (this.fields[evt.target.dataset.index]) {
        this.fields[evt.target.dataset.index].blur = true
        this.fields[evt.target.dataset.index].valid = true
        fieldValid = true
        // console.info(' set this object true', this.fields[evt.target.dataset.index])
      }
      // validate specific fields...
      if (evt.target.dataset.index === 'address.country') {
        if (evt.target.value === 'other') {
          // this.fields['address.code'].blur = true
          this.fields['address.code'].valid = true
          this.fields['address.code'].required = false
          // this.fields['address.code'].blur = true
          this.fields['address.prov'].valid = true
          this.fields['address.prov'].required = false
          fieldValid = true
        } else {
          const _c = this.regions.find(r => r.countryCode === evt.target.value)
          if (_c.postalcode && !this.location.address.code) {
            this.fields['address.code'].valid = false
            this.fields['address.code'].required = true
          }
          if (_c.province && !this.location.address.prov) {
            this.fields['address.prov'].valid = false
            this.fields['address.prov'].required = true
          }
          fieldValid = true
        }
      }
      if (evt.target.dataset.index === 'name') {
        if (this.locations.find(l => l.name === val)) {
          this.fields.name.valid = false
          this.fields.name.error = 'Location with that name already exists'
        }
      }
      if (!this.canSubmit && fieldValid) {
        this.$emit('input', {
          field:
            evt.target.dataset.index === 'other-country'
              ? 'address.country'
              : evt.target.dataset.index,
          value: evt.target.value
        })
      }
      // console.info(` ::: mask -- ${evt.target.dataset.index}, ${evt.target.value} = ${val}`)
    },
    validate() {
      const fieldsValid = Object.values(this.fields)
        .filter(f => f.required)
        .every(f => f.blur && f.valid)
      // console.info(' ::: location address validate', fieldsValid)
      if (!fieldsValid) {
        for (const f in this.fields) {
          if (!this.fields[f].required) continue
          if (!this.fields[f].blur) {
            this.fields[f].valid = false
            this.$emit('input', {
              field: f,
              value: this.location.address[f.replace('address.', '')]
            })
          }
        }
      }
    },
    submit() {
      if (!this.invalid) {
        console.info(' ::: submit', this.location)
        const locationData = Object.assign({}, this.location)
        if (locationData.address.country === 'other') {
          locationData.address.country = this.userCountry
          locationData.userCountry = true
        } else if (locationData.userCountry) {
          delete locationData.userCountry
        }
        if (typeof locationData.owner !== 'string') {
          locationData.owner = locationData.owner._id
        }
        const url = this.newlocation
          ? '/locations'
          : `/locations/${locationData._id}`
        this.$http
          .post(url, locationData)
          .then(() => {
            // console.info(' ::: location post response', res)
            this.getLocations()
            this.$emit('hide')
          })
          .catch(err => {
            console.error(' :: error saving location data:', err)
          })
      } else {
        console.log(" === can't submit: invalid", this.fields)
      }
    },
    cancel() {
      // console.info(' cancel -- hide modal')
      this.$emit('hide')
    }
  }
}
</script>

<style scoped>
.actions {
  clear: both;
  text-align: right;
  margin: 1rem 0;
  padding-bottom: 2rem;
}
</style>
